import Alpine from 'alpinejs'

export default (() => {
  Alpine.data('dropdown', function dropdown(conf = {}) {
    let mounted = false
    const {
      open = false,
      loading = false,
      clickHandler = clickHandlerDefault,
      reset = resetDefault,
      items = [],
      disabledItems = [],
      inactive = false,
      preProcessItems = false,
      selected = '',
      emptyItem = null,
      disabled,
    } = conf
    const processedDisabledItems =
      Object.keys(disabledItems).map((key) => disabledItems[key].value) || []

    return {
      init() {
        Alpine.effect(() => {
          let itemsLength = this.items.length
          this.disabled = itemsLength <= 0
        })
      },
      findInArray(key, value, list) {
        const iterator = (list) => {
          for (let index = 0; index < list.length; index++) {
            const element = list[index]
            const result =
              element[key] === value
                ? element
                : Array.isArray(element.choices)
                ? iterator(element.choices)
                : null
            if (result) return result
          }

          return null
        }
        return iterator(list)
      },
      getSelection(key, fallback) {
        return this.selected && this.selected !== ''
          ? (this.findInArray('value', this.selected, this.items)?.[key] || fallback)
          : fallback
      },
      open,
      selected,
      inactive,
      loading,
      reset,
      clickHandler,
      emptyItem,
      items: preProcessItems
        ? Alpine.store('helpers').isObject(items)
          ? prepareObjectList(items, processedDisabledItems)
          : prepareItems(items, processedDisabledItems)
        : items,
      disabled:
        typeof disabled === 'boolean'
          ? disabled
          : items.length <= 1
          ? true
          : false,
      bindings: {
        toggleButton: {
          ['@click.prevent']() {
            this.open = true
          },
          [':disabled']() {
            return this.disabled
          },
          [':aria-expanded']() {
            return this.open
          },
          [':class']() {
            return {
              'cursor-pointer': !this.disabled,
              'opacity-50': this.disabled,
            }
          },
        },
        original: {
          ['x-effect']() {
            const selected = this.selected
            if (mounted) {
              this.$el.value = selected
              this.$el.dispatchEvent(new Event('change'))
            } else {
              mounted = true
            }
          },
        },
        list: {
          ['x-show']() {
            return this.open
          },
          ['@click.outside']() {
            this.open = false
          },
          ['x-cloak']: true,
        },
      },
    }

    function clickHandlerDefault($event, url) {
      this.open = false
      this.disabled = true
      this.loading = true
      fetch(url)
        .then((res) => res.text())
        .then((data) => {
          $event.target.closest('[data-ajax-parent]').innerHTML = data
        })
    }

    function resetDefault($event, url) {
      this.open = false
      this.disabled = false
      this.loading = true
      fetch(url)
        .then((res) => res.text())
        .then((data) => {
          $event.target.closest('[data-ajax-parent]').innerHTML = data
        })
    }
  })
})()

function prepareItems(list, disabled = []) {
  return list.map((item) => {
    return { key: item.value, disabled: disabled.includes(item.value), ...item }
  })
}

function prepareObjectList(list, disabled = []) {
  return Object.keys(list).map((key) => {
    let item = list[key]
    if (typeof item.choices !== 'undefined' && !Array.isArray(item.choices))
      item.choices = Alpine.store('helpers').isObject(item.choices)
        ? Object.values(item.choices)
        : [item.choices]
    if (typeof item.choices) item.choices = prepareItems(item.choices)
    return { key, label: key, disabled: disabled.includes(item.value), ...item }
  })
}
